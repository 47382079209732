import { createMuiTheme } from '@material-ui/core/styles';

import Logo from './images/logo.svg';

export { Logo };

const custom = {
	primary: '#F5B4D2',
	secondary: '#00553C',
	tertiary: '#d1d1d1',
	subPrimary: '#C3E1CD',
	subSecondary: '#F0F0DC',
	subTertiary: '#3e3d3d',
	general: '#F0F0DC',
	loginViewBackground: '#3e3d3d',
	loginPaperColor: '#00553C',
	iconColor: '#3e3d3d',
	listIconColor: '#C3E1CD',
	mainBackground: '#3e3d3d',
	dialogActionsBase: '#3e3d3d',
	chipColor: '#ffffff',
	chipBackground: '#ffffff',
	drawerBackground: '#00553C',
	linkColor: '#00553C',
	drawerText: {
		fontSize: '16px',
		fontWeight: 'bold',
		color: '#C3E1CD'
	},
};

const theme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: {
			main: custom.primary,
		},
		secondary: {
			main: custom.subPrimary,
			dark: custom.subTertiary,
		},
		background: {
			paper: custom.subSecondary,
			default: custom.general,
		},
		error: {
			main: '#732D41',
		},
	},
	typography: {
		useNextVariants: true,
		// Use the system font.
		fontFamily:
			'-apple-system,system-ui,BlinkMacSystemFont,' +
			'"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
		body2: {
			color: custom.subTertiary,
		},
		body1:{
			color: custom.subTertiary,
		},
		h3: {
			color: custom.subPrimary,
		},
		h4: {
			fontSize: '18px',
			fontWeight: '500',
			color: custom.subPrimary
		},
		h5: {
			margin: '0.5em 0',
			fontWeight: 'bold',
			color: custom.subPrimary
		},
		h6: {
			color: custom.subPrimary
		}
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				body: {
					backgroundColor: custom.mainBackground,
				},
			},
		},
		MuiPaper: {
			root: {
				color: custom.subTertiary,
			}
		},
		MuiDrawer: {
			paper: {
				background: custom.drawerBackground
			}
		},
		MuiIconButton: {
			root: {
				color: custom.subTertiary
			}
		},
		MuiChip: {
			root: {
				color: custom.chipColor,
				backgroundColor: custom.chipBackground + '4d',
			},
			label: {
				color: custom.subTertiary,
				'&:hover': {
					color: custom.subTertiary
				},
			},
			clickable:{
				'&:hover': {
					backgroundColor: custom.tertiary
				},
				'&:focus': {
					backgroundColor: custom.chipBackground + '4d',
					'&:hover': {
						backgroundColor: custom.tertiary,
					},
				},
			},
			clickableColorSecondary: {
				'&:hover': {
					backgroundColor: custom.tertiary
				},
			},

		},
		MuiFormLabel: {
			root: {
				'&$disabled': {
					color: custom.subTertiary,
				}
			},
		},
		MuiInputLabel: {
			root: {
				color: custom.subTertiary,
			},
			shrink: {
				transform: 'translate(0, 0)',
				textTransform: 'capitalize',
			},
		},
		MuiInputBase: {
			input: {
				color: custom.subTertiary,
				position: 'relative',
				background: custom.subPrimary + '4d',
				border: '1px inset rgba(0,41,77, 0.3)',
				borderRadius: '3px',
			},
			multiline: {
				padding: '0',
			},
		},
		MuiNativeSelect: {
			icon: {
				color: custom.subTertiary,
			},
			select: {
				width: 'calc(100% - 20px)',
				paddingLeft: '5px',
				paddingRight: '20px',
				'&:focus':{
					color: '#111',
					backgroundColor: custom.subPrimary + 'fd',
				},
			},
		},
		MuiTableCell: {
			head: {
				fontSize: '13px',
				color: custom.subTertiary,
			},
			body: {
				color: custom.subTertiary,
			},
			root: {
				borderBottom: '1px solid #003150',
			},
			footer: {
				color: custom.subTertiary,
			},
		},
		MuiTabs: {
			root: {
				background: custom.secondary,
				color: custom.tertiary,
			},
			indicator: {
				background: custom.subSecondary,
				height: '5px',
			},
		},
		MuiTab: {
			root: {
				"&:hover":{
					color: '#002039',
					opacity: '1',
					background: custom.subSecondary,
				},
				color: '#002039',
				"&$selected":{
					color: custom.primary,
					background: custom.subPrimary,
				}
			},
			fullWidth: {
				fontSize: '15px',
			}
		},
		MuiPickersCalendarHeader: {
			dayLabel: {
				color: custom.subTertiary,
			}
		},
		MuiPickersDay: {
			day: {
				color: custom.subTertiary,
			},
			daySelected: {
				color: custom.secondary,
				backgroundColor: custom.tertiary
			}
		},
		MuiInputAdornment: {
			positionEnd: {
				color: custom.subTertiary,
				fontSize: '13px',
				fontWeight: 'bold'
			}
		},
		MuiSelect: {
			icon: {
				color: custom.iconColor,
			}
		},
		MuiListItemIcon: {
			root: {
				color: custom.listIconColor
			}
		},
		MuiListItemText: {
			primary: {
				color: custom.tertiary
			},
			secondary: {
				color: custom.subTertiary,
			}
		},
		MuiDialogTitle: {
			root: {
				'& *': {
					color: custom.primary
				}
			}
		},
		MuiDialogContentText:{
			root: {
				color: custom.subTertiary,
			}
		}
	},
	deleteIcon: {
		color: custom.iconColor,
		height: '28px',
		width: '28px',
	},
	checkBox: {
		alignSelf: 'flex-end',
		color: custom.subTertiary,
	},
	adminRoot: {
		display: 'flex',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	logoSlot: {
		padding: '5px'
	},
	//Login component START
	loginRoot: {
		display: 'flex',
		height: '100vh',
		backgroundColor: custom.loginViewBackground,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	loginPaper: {
		padding: '3em',
		marginTop: '-30vh',
		background: custom.loginPaperColor,
	},
	//Login component END
	asyncSelect: {
		color: custom.subTertiary,
		focused: {
			color: custom.tertiary,
		},
		selected: {
			color: custom.subPrimary,
		}
	},
	linkColor: {
		color: custom.linkColor,
	},
	resourceIconColor: {
		color: custom.iconColor,
	},
	drawerText: custom.drawerText,
});

export default theme;
